import React from "react"

import Layout from "../../components/layout"
import { Container } from 'react-bootstrap';
import Seo from "../../components/seo"

const SpaceFuel = (props) => (
    <Layout>
        <Seo title="Space Fuel | Game" />
        <Container style={{ textAlign: "center", listStylePosition: "inside" }}>
            <h1><strong>Space Fuel</strong></h1>
            <iframe title="space-fuel-trailer" width="560" height="315" src="https://www.youtube.com/embed/EhZTNVUEc9w" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>Play short retro arcade shoot 'em up. Pilot your ship through waves of enemy bullets!
            Beat your high score! See how far you can go. Meet and defeat BOSS at the end of each stage. Beat your high score.
                </p>
            <p>Features:</p>
            <ul style={{ padding: 0 }}>
                <li>Different enemy ships and patterns</li>
                <li>Fight different ships with unique weapons and attack patterns</li>
                <li>Retro pixel art graphics</li>
                <li>Chiptune music</li>
                <li>5 different weapon to collect</li>
                <li>LASERS!</li>
            </ul>
            <iframe title="space-fuel-steam" src="https://store.steampowered.com/widget/1361720/" frameBorder="0" width="646" height="190"></iframe>
            <iframe title="space-fuel-itch" frameBorder="0" src="https://itch.io/embed/756477?bg_color=222222&amp;fg_color=eeeeee&amp;border_color=363636" width="552" height="167"><a href="https://mxtgames.itch.io/space-fuel">Space Fuel (demo) by MXT Games</a></iframe>
        </Container>
    </Layout>
)

export default SpaceFuel